//base styles
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// scrollbar
/* width */
::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(35, 49, 86, 0.4);
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 6px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(35, 49, 86, 0.8); 
  border-radius: 6px;
  &:hover {
    background-color: rgba(35, 49, 86, 1); 
  }
}