@import "~bootstrap/scss/bootstrap";

.booking-navbar {
    background-color: #274156;
    color: #fff;
    .btn {
        color: #fff;
        &:focus {
            box-shadow: none;
        }
        @media (max-width: 991px) {
            &:hover {
                color: #fff;
            }
        }
    }
}
