@import "~bootstrap/scss/bootstrap";

.mobile-court {
    .court-name {
        background-color: #441804;
        color: #fff;
        .disable-button {
            border: 1px solid #fff;
            margin-top: 2px;
            margin-bottom: 2px;
            display: block;
            text-align: center;
            outline: none;
            color: #fff;
            background-color: transparent;
            border-radius: 10px;
            transition: 0.25s;
            cursor: pointer;
            &:hover {
                background-color: #fff;
                color: #000;
            }
        }
        &.top-court {
            box-shadow: 2px 4px 8px #1a0000;
        }
        &.bottom-court {
            box-shadow: 0px -2px 8px #1a0000;
        }
    }
    .btn {
        color: #fff;
        &:focus {
            box-shadow: none;
        }
        @media (max-width: 991px) {
            &:hover {
                color: #fff;
            }
        }
    }
}