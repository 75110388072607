@import "~bootstrap/scss/bootstrap";

.admin-page {
    .nav-link {
        cursor: pointer;
        color: #aaa;
        &:hover {
            color: #000;
        }
        &.active {
            color: #000;
            font-weight: bold;
        }
    }
    .add-button {
        background-color: #274156;
        color: #fff;
    }
}
