@import "~bootstrap/scss/bootstrap";

.slot {
    box-shadow: 2px 4px 8px #888888;
    transition: 200ms;
    .time-col {
        span {
            width: 100%;
            font-weight: 600;
        }
    }
    &.empty {
        border-bottom: 1px solid rgb(201, 201, 201);
        background-color: rgba(255, 255, 255, 0.8);
        cursor: pointer;
        .time-col {
            border-right: 1px solid #aaa;
        }
        &:hover {
            background-color: rgba(40, 167, 69, 1);
            border: 1px solid #1b722f;
            color: #fff;
            .time-col {
                border-right: 1px solid #1b722f;
            }
        }
        &.gray {
            background-color: rgba(194, 194, 194, 0.5);
            &:hover {
                background-color: rgba(219, 219, 219, 0.5);
                border: none;
                border-bottom: 1px solid rgb(201, 201, 201);
                color: #000;
                .time-col {
                    border-right: 1px solid #aaa;
                }
                &.disabled {
                    background-color: rgba(194, 194, 194, 0.5);
                }
            }
        }
    }
    &.friendly {
        background-color: rgba(0, 123, 255, 0.8);
        box-shadow: 2px 4px 8px #112581cc;
        .time-col {
            border-right: 1px solid #1f41ddcc;
        }
        &:hover {
            background-color: rgba(0, 123, 255, 1);
            border: 1px solid #132a91cc;
            color: #fff;
            .time-col {
                border-right: 1px solid #132a91cc;
            }
        }
    }
    &.social {
        background-color: rgba(147, 32, 237, 0.8);
        box-shadow: 2px 4px 8px #250000;
        .time-col {
            border-right: 1px solid #9220e9;
        }
        &:hover {
            background-color: rgba(147, 32, 237, 1);
            border: 1px solid #7017b4;
            color: #fff;
            .time-col {
                border-right: 1px solid #7017b4;
            }
        }
    }
    &.coach {
        background-color: rgba(237, 119, 32, 0.8);
        box-shadow: 2px 4px 8px #c2601a;
        .time-col {
            border-right: 1px solid #c2601a;
        }
        &:hover {
            background-color: rgba(237, 119, 32, 1);
            border: 1px solid #c2601a;
            color: #fff;
            .time-col {
                border-right: 1px solid #c2601a;
            }
        }
    }
    &.tournament {
        background-color: rgba(220, 53, 69, 0.8);
        box-shadow: 2px 4px 8px #250000;
        .time-col {
            border-right: 1px solid #dd3445;
        }
        &:hover {
            background-color: rgba(220, 53, 69, 1);
            border: 1px solid #6e1a22;
            color: #fff;
            .time-col {
                border-right: 1px solid #6e1a22;
            }
        }
    }
    &.deletable {
        cursor: pointer;
    }
    &.disabled {
        cursor: auto;
    }
}
