@import "~bootstrap/scss/bootstrap";

.cookie-banner {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    bottom: 0;
    color: #fff;
    width: 100%;
    .btn {
        &:focus {
            box-shadow: none !important;
        }
        &:hover {
            background-color: #aaa;
        }
    }
}
