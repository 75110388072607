@import "~bootstrap/scss/bootstrap";

.court-table {
    .nav-link {
        cursor: pointer;
        color: #aaa;
        &:hover {
            color: #000;
        }
        &.active {
            color: #000;
            font-weight: bold;
        }
    }
    .btn {
        &:focus {
            box-shadow: none !important;
        }
        &:hover {
            background-color: #aaa;
        }
    }
}
