@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
.App {
  font-family: "Lato", sans-serif;
  height: 100%;
  width: 100%;
  // try fix mobile input not writing
  input,
  input:before,
  input:after {
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
  }
}