@import "~bootstrap/scss/bootstrap";

.desktop-header {
    background-color: #441804;
    text-transform: uppercase;
    .nav-item {
        font-size: 16px;
        .btn:focus {
            box-shadow: none;
        }
        .settings-btn {
            color: $secondary;
            &:hover {
                color: #fff;
            }
        }
    }
    @media (max-width: 1200px) {
        font-size: 14px;
        .navbar-brand {
            font-size: 14px;
        }
        .nav-item {
            font-size: 14px;
        }
    }
}
