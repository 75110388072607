@import "~bootstrap/scss/bootstrap";

.privacy {
    .btn {
        color: #000;
        &:focus {
            box-shadow: none !important;
        }
        &:hover {
            // color: $primary;
            text-decoration: underline;
        }
    }
    .nested li {
        display: block;
        position: relative;
    }

    .nested {
        margin-bottom: 0;
        counter-reset: number;
    }

    .parent .nested {
        counter-reset: letter;
    }

    .parent .nested li::before {
        content: counter(letter, lower-alpha) ".";
        counter-increment: letter;
    }

    .nested li::before {
        content: counter(number) ".";
        counter-increment: number;
        position: absolute;
        margin-right: 100%;
        right: 10px;
    }
    .text {
        color: $secondary;
    }
    .dark {
        color: #000;
    }
    &.fede {
        background-color: rgba(237, 47, 177, .8);
        color: #fff;
        .text {
            color: #e2e2e2;
        }
        .btn {
            color: #fff;
        }
        a {
            color: #fff;
        }
    }
}
