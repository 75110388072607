@import "~bootstrap/scss/bootstrap";

.login-container {
    overflow: hidden;
    background-image: url("../../../assets/login_bkg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    .box {
        padding: 40px;
        background-color: #441804;
        text-align: center;
        transition: 0.25s;
        .logo {
            max-width: 250px;
        }
        h1 {
            color: white;
            text-transform: uppercase;
            font-weight: 500;
        }
        input {
            border: 0;
            background: none;
            display: block;
            margin: 20px auto;
            text-align: center;
            border: 2px solid #3498db;
            padding: 10px 10px;
            width: 250px;
            outline: none;
            color: white;
            border-radius: 24px;
            transition: 0.25s;
            &:focus {
                width: 300px;
                border-color: #2ecc71;
            }
        }
        .login-button {
            border: 0;
            background: none;
            display: block;
            margin: 20px auto;
            text-align: center;
            border: 2px solid #2ecc71;
            padding: 14px 40px;
            outline: none;
            color: white;
            border-radius: 24px;
            transition: 0.25s;
            cursor: pointer;
            &:hover {
                background: #2ecc71;
            }
        }
        @media (max-width: 991px) {
            opacity: 0.9;
            input[type="text"],
            input[type="password"] {
                &:focus {
                    width: 250px;
                }
            }
            .login-button {
                background: #2ecc71;
            }
        }
    }
}
