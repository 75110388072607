@import "~bootstrap/scss/bootstrap";

.app-header {
    //base settings
    background-color: #441804;
    box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.5);
    width: 100%;
    //base link style
    .btn {
        color: #fff;
        &:focus {
            box-shadow: none;
        }
        &:hover {
            color: #aaa;
        }
    }
}

// off canvas menu
.off-canvas {
    //base styling
    position: fixed;
    z-index: 1;
    top: 0;
    opacity: 0;
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
    transition: 1s;
    background-color: rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    font-size: 14px;
    color: #fff;
    // links
    .nav-link {
        color: #9eb2c3;
        font-size: 16px;
        .btn:focus {
            box-shadow: none;
        }
        .settings-btn {
            color: #9eb2c3;
            &:hover {
                color: #fff;
            }
        }
        &.active,
        &:hover {
            color: #fff;
        }
    }
    .logout-button {
        color: #9eb2c3;
        border: 1px #9eb2c3 solid;
        &:hover {
            color: #fff;
            background-color: $secondary;
            border: 1px $secondary solid;
        }
    }
    // overlay
    .overlay {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-color: transparent;
    }
    // name
    .name {
        color: #fff;
    }
    // close button
    .close-button {
        position: absolute;
        cursor: pointer;
        right: 0;
        z-index: 3;
        transition: 1.5s;
        color: #fff;
    }
    // link parts
    .sidebar {
        position: absolute;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 1%;
        width: 200px;
        height: 100%;
        margin: 0;
        right: 0;
        top: 0;
        z-index: 3;
        -webkit-transform: translateX(30%);
        -ms-transform: translateX(30%);
        -o-transform: translateX(30%);
        transform: translateX(30%);
        transition: 1s;
        background-color: rgba(47, 17, 3, 1);
        .app-logo {
            transition: 1s;
        }
        .sidebar-links {
            width: 100%;
            text-align: right;
            font-weight: bold;
            font-size: calc(10px + 2vmin);
            padding: 5%;
        }
        //user name
        .user-info-section {
            font-size: 14px;
        }
    }
    // when active
    &.active {
        opacity: 1;
        z-index: 1;
        width: 100%;
        height: 100%;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        transition: 1.5s;
        .close-button {
            -webkit-transform: translateX(-90vw);
            -ms-transform: translateX(-90vw);
            -o-transform: translateX(-90vw);
            transform: translateX(-90vw);
        }
        .sidebar {
            -webkit-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
            transform: translateX(0);
        }
    }
}
