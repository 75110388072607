.submit-button {
    border: 0;
    display: block;
    margin: 20px auto;
    text-align: center;
    padding: 7px 20px;
    outline: none;
    color: white;
    background-color: #2ecc71;
    border-radius: 24px;
    transition: 0.25s;
    cursor: pointer;
    &:hover {
        background-color: #25a85c;
    }
}
