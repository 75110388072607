@import "~bootstrap/scss/bootstrap";
.admin-tabs {
    .nav-tabs {
        background-color: #cecece;
        border-bottom: 1px solid #b6b6b6;
        .nav-link {
            cursor: pointer;
            color: #aaa;
            &:hover {
                color: #000;
            }
            &.active {
                color: #000;
                font-weight: bold;
            }
        }
    }
}
