@import "~bootstrap/scss/bootstrap";

.add-credits-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  .card {
    .close-icon {
      cursor: pointer;
      color: rgba(56, 56, 56, 0.8);
      transition: 200ms;
      &:hover {
        color: rgba(56, 56, 56, 1);
        transform: scale(1.1);
      }
    }
    .box {
      width: 500px;
      padding-bottom: 20px;
      padding-left: 40px;
      padding-right: 40px;
      background: #fff;
      text-align: center;
      transition: 0.25s;
    }
    .submit-button {
      border: 0;
      display: block;
      margin: 20px auto;
      text-align: center;
      padding: 14px 40px;
      outline: none;
      color: white;
      background-color: #2ecc71;
      border-radius: 24px;
      transition: 0.25s;
      cursor: pointer;
      &:hover {
        background-color: #25a85c;
      }
    }
  }
  @media (max-width: 991px) {
      overflow-y: scroll;
      overflow-x: hidden;
  }
}
